import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  TextField,
  useGetList,
  useRecordContext,
} from "react-admin";
import React, { useEffect, useState } from "react";
import { endOfMonth, startOfMonth } from "date-fns";

interface Ticket {
  id: string;
  name: string;
  description: string;
  amount: number;
  productType: string;
}

interface Event {
  id: string;
  name: string;
  description: string;
  summary: string;
  tickets: Ticket[];
  mainImage: {
    src: string;
    title: string;
  };
  location: {
    name: string;
    address: string;
  };
}

interface EventInstance {
  id: string;
  eventId: string;
  startDate: Date;
  endDate: Date;
  description: string;
}

interface EventSummary {
  totalEvents: number;
  totalTickets: number;
  averageTicketPrice: number;
}

const TicketDisplay: React.FC<{}> = () => {
  const record = useRecordContext();
  if (!record.tickets) return null;
  return (
    <span>
      {record.tickets
        .map(
          (ticket: Ticket) =>
            `${ticket.name}: $${(ticket.amount / 100).toFixed(2)}`
        )
        .join(", ")}
    </span>
  );
};

export const MonthlyEventsReport: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [eventSummary, setEventSummary] = useState<EventSummary>({
    totalEvents: 0,
    totalTickets: 0,
    averageTicketPrice: 0,
  });

  // Get event instances for the selected month
  const { data: eventInstances, isLoading: instancesLoading } =
    useGetList<EventInstance>("eventInstances", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "startDate", order: "ASC" },
      filter: {
        startDate: startOfMonth(selectedMonth),
        endDate: endOfMonth(selectedMonth),
      },
    });

  // Get corresponding events for the instances
  const { data: events, isLoading: eventsLoading } = useGetList<Event>(
    "events",
    {
      pagination: { page: 1, perPage: 100 },
    }
  );

  useEffect(() => {
    if (eventInstances && events) {
      const summary = calculateEventSummary(eventInstances, events);
      setEventSummary(summary);
    }
  }, [eventInstances, events]);

  const calculateEventSummary = (
    instances: EventInstance[],
    events: Event[]
  ): EventSummary => {
    let totalTickets = 0;
    let totalPrice = 0;
    let ticketCount = 0;

    instances?.forEach((instance) => {
      const event = events?.find((e) => e.id === instance.eventId);
      if (event?.tickets) {
        event.tickets.forEach((ticket) => {
          totalTickets++;
          totalPrice += ticket.amount;
          ticketCount++;
        });
      }
    });

    return {
      totalEvents: instances?.length || 0,
      totalTickets: totalTickets,
      averageTicketPrice: ticketCount > 0 ? totalPrice / ticketCount : 0,
    };
  };

  if (instancesLoading || eventsLoading) {
    return <div>Loading...</div>;
  }

  const EventFilter: React.FC<{}> = (props: any) => {
    const defaultMonth = new Date();
    return (
      <Filter {...props}>
        <DateInput
          label="Month"
          source="month"
          defaultValue={defaultMonth}
          onChange={(event) => setSelectedMonth(event.value)}
        />
      </Filter>
    );
  };

  return (
    <Box>
      {/* Summary Cards */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Events</Typography>
              <Typography variant="h4">{eventSummary.totalEvents}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Ticket Types</Typography>
              <Typography variant="h4">{eventSummary.totalTickets}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Average Ticket Price</Typography>
              <Typography variant="h4">
                ${(eventSummary.averageTicketPrice / 100).toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Events List */}
      <List
        resource="eventInstances"
        filter={{ month: selectedMonth }}
        filters={<EventFilter />}
        perPage={25}
        sort={{ field: "startDate", order: "ASC" }}
      >
        <Datagrid>
          <ReferenceField source="eventId" reference="events" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <DateField source="startDate" showTime />
          <DateField source="endDate" showTime />
          <ReferenceField
            source="eventId"
            reference="events"
            label="Summary"
            link={false}
          >
            <TextField source="summary" />
          </ReferenceField>
          <ReferenceField
            source="eventId"
            reference="events"
            label="Ticket Types"
            link={false}
          >
            <TicketDisplay />
          </ReferenceField>
        </Datagrid>
      </List>
    </Box>
  );
};

export default MonthlyEventsReport;
