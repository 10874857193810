import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { RaRecord } from "react-admin";
import React from "react";
import { timeToDateString } from "../utils";

interface LineItem {
  id: string;
  name: string;
  sku: string;
  quantity: number;
  amount: number;
  giftMessage?: string;
  giftDate?: string;
  eventName?: string;
  eventId?: string;
}

interface OrderRecord extends RaRecord {
  createdate: Date;
  amount: number;
  status: string;
  memberId: string;
  lineItems: LineItem[];
}

interface OrderGiftDetailsProps {
  source?: string;
  record?: OrderRecord;
}

export const OrderGiftDetails: React.FC<OrderGiftDetailsProps> = ({
  record,
}) => {
  if (!record?.lineItems) return null;

  const giftItems = record.lineItems.filter((item) => item.giftMessage);

  if (!giftItems.length) {
    return null;
  }

  return (
    <Card sx={{ mt: 2, mb: 2 }}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <CardGiftcardIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="h6" color="primary">
            Gift Order Details
          </Typography>
        </Box>
        <List>
          {giftItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Typography variant="subtitle1">{item.name}</Typography>
                      <Chip
                        label="Gift Item"
                        size="small"
                        color="primary"
                        variant="outlined"
                        sx={{ ml: 1 }}
                      />
                    </Box>
                  }
                  secondary={
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Gift Message:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          pl: 2,
                          borderLeft: "2px solid",
                          borderColor: "primary.main",
                          fontStyle: "italic",
                        }}
                      >
                        {item.giftMessage}
                      </Typography>
                      {item.giftDate && (
                        <Typography
                          variant="caption"
                          display="block"
                          sx={{ mt: 1 }}
                        >
                          Added on: {timeToDateString(item.giftDate)}
                        </Typography>
                      )}
                    </Box>
                  }
                />
              </ListItem>
              {index < giftItems.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

interface GiftChipDisplayProps {
  record?: OrderRecord;
}

export const GiftChipDisplay: React.FC<GiftChipDisplayProps> = ({ record }) => {
  if (!record?.lineItems) return null;

  const giftItems = record.lineItems.filter((item) => item.giftMessage);
  if (!giftItems.length) return null;

  return (
    <Chip
      label={`${giftItems.length} Gift Item${giftItems.length > 1 ? "s" : ""}`}
      size="small"
      color="primary"
      icon={<CardGiftcardIcon />}
      sx={{ ml: 1 }}
    />
  );
};
