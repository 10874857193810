import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";
import {
  GiftChipDisplay,
  OrderGiftDetails,
} from "../components/OrderGiftDetails";

import { centsToCurrency } from "../utils";

interface ShippingGroup {
  address: {
    name: string;
    address: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone?: string;
  };
  items: LineItem[];
}

interface LineItem {
  id: string;
  name: string;
  sku: string;
  quantity: number;
  amount: number;
  giftMessage?: string;
  giftDate?: string;
  eventName?: string;
  eventId?: string;
}

interface OrderRecord extends RaRecord {
  createdate: Date;
  amount: number;
  status: string;
  memberId: string;
  lineItems: LineItem[];
  discount?: {
    code: string;
    amountOff: number;
  };
  shippingGroups?: ShippingGroup[];
  shippingAddress?: {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
}

const ShippingGroupsDisplay = () => {
  const record = useRecordContext<OrderRecord>();
  if (!record?.shippingGroups?.length) {
    return <Typography>No shipping required</Typography>;
  }

  return (
    <Box>
      {record.shippingGroups.map((group: ShippingGroup, index: number) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">Shipping Group {index + 1}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Shipping Address</Typography>
                <Typography>{group.address.name}</Typography>
                <Typography>{group.address.address}</Typography>
                {group.address.address2 && (
                  <Typography>{group.address.address2}</Typography>
                )}
                <Typography>
                  {group.address.city}, {group.address.state}{" "}
                  {group.address.zip}
                </Typography>
                <Typography>{group.address.country}</Typography>
                {group.address.phone && (
                  <Typography>Phone: {group.address.phone}</Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Items</Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group.items.map((item) => (
                      <TableRow key={item.sku}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">
                          {centsToCurrency(item.amount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const UnshippedItemsDisplay = () => {
  const record = useRecordContext<OrderRecord>();

  const shippedSkus =
    record.shippingGroups?.flatMap((group: ShippingGroup) =>
      group.items.map((item) => item.sku)
    ) || [];

  const unshippedItems = record.lineItems.filter(
    (item) => !shippedSkus.includes(item.sku)
  );

  if (!unshippedItems.length) {
    return null;
  }

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6">Items Without Shipping</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unshippedItems.map((item) => (
              <TableRow key={item.sku}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">
                  {centsToCurrency(item.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const OrderFilter = () => (
  <Filter>
    <Box display="flex" gap={2} width="100%" flexWrap="wrap">
      <Box flex={1} minWidth={200}>
        <TextInput label="Search Order ID" source="q" alwaysOn />
      </Box>
    </Box>
  </Filter>
);

export const OrderShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdate" label="Order Date" showTime />
      <ReferenceField source="memberId" reference="members">
        <TextField source="displayName" />
        <br />
        <TextField source="email" />
      </ReferenceField>
      <TextField source="status" />
      <FunctionField<OrderRecord>
        source="amount"
        render={(record) => (record ? centsToCurrency(record.amount) : "")}
      />
      <TextField source="discount.code" />
      <OrderGiftDetails source="lineItems" />
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Shipping Information
      </Typography>
      <ShippingGroupsDisplay />
      <UnshippedItemsDisplay />
    </SimpleShowLayout>
  </Show>
);

export const OrderList = () => {
  return (
    <List
      filters={<OrderFilter />}
      sort={{ field: "createdate", order: "DESC" }}
      perPage={50}
    >
      <Datagrid>
        <DateField source="createdate" label="Order Date" showTime />
        <ReferenceField source="memberId" reference="members" link="show">
          <TextField source="displayName" />
        </ReferenceField>
        <FunctionField<OrderRecord>
          label="Amount"
          render={(record) => (record ? centsToCurrency(record.amount) : "")}
        />
        <TextField source="status" />
        <FunctionField<OrderRecord>
          label="Items"
          render={(record) =>
            record ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <span>
                  {record.lineItems
                    ?.map((item: LineItem) => `${item.name} (${item.quantity})`)
                    .join(", ")}
                </span>
                <GiftChipDisplay record={record} />
              </Box>
            ) : null
          }
        />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const OrderCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>

      <DateTimeInput
        source="createdate"
        defaultValue={new Date().toUTCString()}
      />
    </SimpleForm>
  </Create>
);

export const OrderEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={[
          { id: "new", name: "New" },
          { id: "processing", name: "Processing" },
          { id: "shipped", name: "Shipped" },
          { id: "delivered", name: "Delivered" },
          { id: "cancelled", name: "Cancelled" },
        ]}
      />
      <TextInput source="trackingNumber" />
      <TextInput disabled source="createdate" />
      <TextInput disabled source="lastupdate" />
    </SimpleForm>
  </Edit>
);
const OrdersAdmin = {
  list: OrderList,
  show: OrderShow,
  edit: OrderEdit,
};
export default OrdersAdmin;
