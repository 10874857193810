import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  RichTextField,
  Show,
  SimpleForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  email,
  required,
} from "react-admin";

import { Grid } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";

const validateUrl = [required()];
const validateEmail = [email()];
const validateRequired = [required()];

export const PartnerList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="summary" />
      <TextField source="website" />
      <BooleanField source="isActive" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const PartnerShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Summary">
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="image.src" label="Image" />
        <TextField source="summary" />
        <RichTextField source="content" label="Bio/Content" />
        <TextField source="website" />
        <TextField source="instagram" />
        <TextField source="facebook" />
        <TextField source="email" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

const transform = (data: any) => {
  // Transform image data if needed
  if (
    data.image &&
    !data.image.src?.src &&
    typeof data.image.src === "object"
  ) {
    data.image = {
      src: data.image.src.rawFile ? data.image.src : data.image.src.src,
      title: data.image.title || "",
    };
  }
  return data;
};

export const PartnerCreate = (props: any) => (
  <Create {...props} transform={transform}>
    <SimpleForm>
      <TextInput
        source="id"
        label="Partner ID"
        validate={validateRequired}
        helperText="unique-identifier-for-partner"
      />
      <TextInput source="name" validate={validateRequired} fullWidth />
      <BooleanInput source="isActive" defaultValue={true} />

      <ImageInput source="image" label="Partner Image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      <TextInput
        source="summary"
        validate={validateRequired}
        multiline
        rows={3}
        fullWidth
      />
      <RichTextInput
        source="content"
        label="Bio/Content"
        validate={validateRequired}
        fullWidth
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="website"
            validate={validateUrl}
            fullWidth
            helperText="Full URL including https://"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="email" validate={validateEmail} fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="instagram"
            validate={validateUrl}
            fullWidth
            helperText="Full Instagram profile URL"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source="facebook"
            validate={validateUrl}
            fullWidth
            helperText="Full Facebook page URL"
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export const PartnerEdit = (props: any) => (
  <Edit {...props} transform={transform}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={validateRequired} fullWidth />
      <BooleanInput source="isActive" />

      <ImageInput source="image" label="Partner Image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      <TextInput
        source="summary"
        validate={validateRequired}
        multiline
        rows={3}
        fullWidth
      />
      <RichTextInput
        source="content"
        label="Bio/Content"
        validate={validateRequired}
        fullWidth
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="website"
            validate={validateUrl}
            fullWidth
            helperText="Full URL including https://"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="email" validate={validateEmail} fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            source="instagram"
            validate={validateUrl}
            fullWidth
            helperText="Full Instagram profile URL"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source="facebook"
            validate={validateUrl}
            fullWidth
            helperText="Full Facebook page URL"
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

const PartnersAdmin = {
  list: PartnerList,
  show: PartnerShow,
  create: PartnerCreate,
  edit: PartnerEdit,
};

export default PartnersAdmin;
