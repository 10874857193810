import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  getDay,
  getHours,
  getMinutes,
  isBefore,
  parseISO,
  set,
  setDate,
  startOfMonth,
} from "date-fns";
import { useCallback, useState } from "react";
import { useCreate, useNotify } from "react-admin";

const REPEAT_OPTIONS = {
  NONE: "NONE",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
} as const;

type RepeatOption = (typeof REPEAT_OPTIONS)[keyof typeof REPEAT_OPTIONS];

const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

const ORDINAL_NUMBERS = ["1st", "2nd", "3rd", "4th", "Last"] as const;

interface EventInstanceCreatorProps {
  event: { id: string; name: string };
  open: boolean;
  onClose: () => void;
}

interface MonthlyPattern {
  type: "date" | "weekday";
  weekday?: (typeof WEEKDAYS)[number];
  ordinal?: (typeof ORDINAL_NUMBERS)[number];
  date?: number;
}

export const EventInstanceCreator = ({
  event,
  open,
  onClose,
}: EventInstanceCreatorProps) => {
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 16)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 16));
  const [repeatType, setRepeatType] = useState<RepeatOption>(
    REPEAT_OPTIONS.NONE
  );

  const [repeatUntil, setRepeatUntil] = useState(
    addMonths(new Date(), 1).toISOString().slice(0, 16)
  );
  const [weeklyDays, setWeeklyDays] = useState<(typeof WEEKDAYS)[number][]>([]);
  const [monthlyPattern, setMonthlyPattern] = useState<MonthlyPattern>({
    type: "date",
  });
  const [description, setDescription] = useState("");
  const [previewDates, setPreviewDates] = useState<
    { startDate: Date; endDate: Date }[]
  >([]);

  const [create] = useCreate();
  const notify = useNotify();

  const handleWeekdayToggle = (day: (typeof WEEKDAYS)[number]) => {
    setWeeklyDays((current) =>
      current.includes(day)
        ? current.filter((d) => d !== day)
        : [...current, day]
    );
  };

  const preserveTime = useCallback((sourceDate: Date, targetDate: Date) => {
    return set(targetDate, {
      hours: getHours(sourceDate),
      minutes: getMinutes(sourceDate),
      seconds: 0,
      milliseconds: 0,
    });
  }, []);

  const findNthWeekday = useCallback(
    (date: Date, n: number, targetDay: number, timeSource: Date) => {
      let current = startOfMonth(date);
      let count = 0;

      // If looking for last occurrence, start from end of month
      if (n === -1) {
        current = endOfMonth(date);
        while (getDay(current) !== targetDay) {
          current = addDays(current, -1);
        }
        return preserveTime(timeSource, current);
      }

      // Find nth occurrence
      while (count < n) {
        if (getDay(current) === targetDay) {
          count++;
          if (count === n) return preserveTime(timeSource, current);
        }
        current = addDays(current, 1);
      }
      return preserveTime(timeSource, current);
    },
    [preserveTime]
  );

  const calculateMonthlyDate = useCallback(
    (baseDate: Date, pattern: MonthlyPattern, timeSource: Date) => {
      if (pattern.type === "date" && pattern.date) {
        const newDate = setDate(baseDate, pattern.date);
        return preserveTime(timeSource, newDate);
      }

      if (pattern.type === "weekday" && pattern.weekday && pattern.ordinal) {
        const weekdayIndex = WEEKDAYS.indexOf(pattern.weekday);
        const ordinalIndex = ORDINAL_NUMBERS.indexOf(pattern.ordinal);
        const n = pattern.ordinal === "Last" ? -1 : ordinalIndex + 1;
        return findNthWeekday(baseDate, n, weekdayIndex, timeSource);
      }

      return preserveTime(timeSource, baseDate);
    },
    [findNthWeekday, preserveTime]
  );

  const calculateInstanceDates = useCallback(() => {
    const dates: { startDate: Date; endDate: Date }[] = [];
    const initialStart = parseISO(startDate);
    const initialEnd = parseISO(endDate);
    let currentDate = initialStart;
    const duration = initialEnd.getTime() - initialStart.getTime();
    const until = parseISO(repeatUntil);

    const addInstance = (date: Date) => {
      const adjustedStart = preserveTime(initialStart, date);
      const adjustedEnd = new Date(adjustedStart.getTime() + duration);
      if (isBefore(adjustedStart, until)) {
        dates.push({
          startDate: adjustedStart,
          endDate: adjustedEnd,
        });
      }
    };

    switch (repeatType) {
      case REPEAT_OPTIONS.NONE:
        addInstance(currentDate);
        break;

      case REPEAT_OPTIONS.DAILY:
        while (isBefore(currentDate, until)) {
          addInstance(currentDate);
          currentDate = addDays(currentDate, 1);
        }
        break;

      case REPEAT_OPTIONS.WEEKLY:
        while (isBefore(currentDate, until)) {
          const dayName = WEEKDAYS[getDay(currentDate)];
          if (weeklyDays.includes(dayName)) {
            addInstance(currentDate);
          }
          currentDate = addDays(currentDate, 1);
        }
        break;

      case REPEAT_OPTIONS.MONTHLY:
        while (isBefore(currentDate, until)) {
          const monthlyDate = calculateMonthlyDate(
            currentDate,
            monthlyPattern,
            initialStart
          );
          if (isBefore(monthlyDate, until)) {
            dates.push({
              startDate: monthlyDate,
              endDate: new Date(monthlyDate.getTime() + duration),
            });
          }
          currentDate = addMonths(currentDate, 1);
        }
        break;
    }

    return dates;
  }, [
    startDate,
    endDate,
    repeatType,
    repeatUntil,
    weeklyDays,
    monthlyPattern,
    calculateMonthlyDate,
    preserveTime,
  ]);

  const handlePreview = () => {
    const dates = calculateInstanceDates();
    setPreviewDates(dates);
  };

  const handleSubmit = async () => {
    const dates = calculateInstanceDates();

    try {
      // Use Promise.all to create all instances concurrently
      await Promise.all(
        dates.map((date) =>
          create("eventInstances", {
            data: {
              eventId: event.id,
              startDate: date.startDate,
              endDate: date.endDate,
              description: description,
            },
          })
        )
      );

      notify("Event instances created successfully");
      onClose();
    } catch (error) {
      notify("Error creating event instances", { type: "error" });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Event Instances - {event?.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Box>
            <TextField
              label="Start Date & Time"
              type="datetime-local"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <TextField
              label="End Date & Time"
              type="datetime-local"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <TextField
            label="Description"
            multiline
            rows={2}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />

          <FormControl>
            <FormLabel>Repeat</FormLabel>
            <RadioGroup
              value={repeatType}
              onChange={(e) => setRepeatType(e.target.value as RepeatOption)}
            >
              {Object.entries(REPEAT_OPTIONS).map(([key, value]) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={key.charAt(0) + key.slice(1).toLowerCase()}
                />
              ))}
            </RadioGroup>
          </FormControl>

          {repeatType !== REPEAT_OPTIONS.NONE && (
            <Box>
              <TextField
                label="Repeat Until"
                type="datetime-local"
                value={repeatUntil}
                onChange={(e) => setRepeatUntil(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}

          {repeatType === REPEAT_OPTIONS.WEEKLY && (
            <Box>
              <FormLabel>Select Days</FormLabel>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
                {WEEKDAYS.map((day) => (
                  <Chip
                    key={day}
                    label={day}
                    onClick={() => handleWeekdayToggle(day)}
                    color={weeklyDays.includes(day) ? "primary" : "default"}
                    sx={{ cursor: "pointer" }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {repeatType === REPEAT_OPTIONS.MONTHLY && (
            <FormControl fullWidth>
              <FormLabel>Monthly Repeat Type</FormLabel>
              <RadioGroup
                value={monthlyPattern.type}
                onChange={(e) => {
                  const type = e.target.value as "date" | "weekday";
                  setMonthlyPattern((prev) => ({
                    type,
                    ...(type === "date"
                      ? { date: 1 }
                      : { weekday: "Monday", ordinal: "1st" }),
                  }));
                }}
              >
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label="Same date each month"
                />
                <FormControlLabel
                  value="weekday"
                  control={<Radio />}
                  label="Specific weekday each month"
                />
              </RadioGroup>

              {monthlyPattern.type === "date" && (
                <TextField
                  type="number"
                  label="Day of Month"
                  value={monthlyPattern.date || 1}
                  onChange={(e) => {
                    const date = Math.min(
                      31,
                      Math.max(1, parseInt(e.target.value))
                    );
                    setMonthlyPattern((prev) => ({ ...prev, date }));
                  }}
                  sx={{ mt: 2 }}
                />
              )}

              {monthlyPattern.type === "weekday" && (
                <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                  <FormControl sx={{ minWidth: 120 }}>
                    <Select
                      value={monthlyPattern.ordinal || "1st"}
                      onChange={(e) =>
                        setMonthlyPattern((prev) => ({
                          ...prev,
                          ordinal: e.target
                            .value as (typeof ORDINAL_NUMBERS)[number],
                        }))
                      }
                    >
                      {ORDINAL_NUMBERS.map((ordinal) => (
                        <MenuItem key={ordinal} value={ordinal}>
                          {ordinal}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ minWidth: 120 }}>
                    <Select
                      value={monthlyPattern.weekday || "Monday"}
                      onChange={(e) =>
                        setMonthlyPattern((prev) => ({
                          ...prev,
                          weekday: e.target.value as (typeof WEEKDAYS)[number],
                        }))
                      }
                    >
                      {WEEKDAYS.map((weekday) => (
                        <MenuItem key={weekday} value={weekday}>
                          {weekday}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </FormControl>
          )}

          {previewDates.length > 0 && (
            <Box>
              <Typography variant="subtitle1">Preview:</Typography>
              <Box sx={{ maxHeight: 200, overflow: "auto" }}>
                {previewDates.map((date, index) => (
                  <Typography key={index} variant="body2">
                    {format(date.startDate, "PPP p")} -{" "}
                    {format(date.endDate, "p")}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePreview}>Preview</Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            !startDate ||
            !endDate ||
            (repeatType === REPEAT_OPTIONS.WEEKLY && weeklyDays.length === 0) ||
            (repeatType === REPEAT_OPTIONS.MONTHLY &&
              monthlyPattern.type === "weekday" &&
              (!monthlyPattern.weekday || !monthlyPattern.ordinal))
          }
        >
          Create Instances
        </Button>
      </DialogActions>
    </Dialog>
  );
};
