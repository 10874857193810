import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
} from "react-admin";

export const VendorList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="businessName" />
      <TextField source="contactName" />
      <TextField source="email" />
      <TextField source="boothSize" />
      <TextField source="status" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export const VendorShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="businessName" />
      <TextField source="contactName" />
      <TextField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="website" />
      <TextField source="boothSize" />
      <TextField source="needsPower" />
      <TextField source="goodsServices" />
      <TextField source="specialRequests" />
      <TextField source="agreedBoothFee" />
      <TextField source="status" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export const VendorEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="businessName" />
      <TextInput source="contactName" />
      <TextInput source="email" />
      <TextInput source="phoneNumber" />
      <TextInput source="website" />
      <SelectInput
        source="boothSize"
        choices={[
          { id: "6ft", name: "6 ft table" },
          { id: "10x10", name: "10x10 booth space" },
          { id: "other", name: "Other" },
        ]}
      />
      <BooleanInput source="needsPower" />
      <TextInput source="goodsServices" multiline />
      <TextInput source="specialRequests" multiline />
      <TextInput source="agreedBoothFee" />
      <SelectInput
        source="status"
        choices={[
          { id: "pending", name: "Pending" },
          { id: "approved", name: "Approved" },
          { id: "rejected", name: "Rejected" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

const VendorsAdmin = {
  list: VendorList,
  show: VendorShow,
  edit: VendorEdit,
};

export default VendorsAdmin;
