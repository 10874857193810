import {
  ArrayField,
  ArrayInput,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceManyCount,
  ReferenceManyField,
  RichTextField,
  Show,
  SimpleForm,
  SimpleFormIterator,
  TabbedShowLayout,
  TextField,
  TextInput,
  WrapperField,
  useGetList,
  useRecordContext,
} from "react-admin";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { EventInstanceCreator } from "../components/EventInstanceCreator";
import { RichTextInput } from "ra-input-rich-text";

const CreateEventInstanceButton = () => {
  const record = useRecordContext<{ id: string; name: string }>();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick} label="Add Instances" variant="contained" />
      <EventInstanceCreator event={record} open={open} onClose={handleClose} />
    </>
  );
};

const transformEventData = (data: any) => {
  if (data.additionalImages && data.additionalImages.length > 0) {
    data.additionalImages = data.additionalImages.map((image: any) => {
      if (typeof image.src === "string") {
        return image;
      }
      if (!image.src?.src) {
        return null;
      }
      return {
        src: image.src.src, // image.src is already a URL string
        title: image.title, // title should be top-level
      };
    });
  }
  return data;
};

const EventFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <BooleanInput source="isActive" label="Active" alwaysOn />
  </Filter>
);

// Helper function to get next instance from a list of instances
interface EventInstance {
  id: string;
  startDate: Date;
  [key: string]: any;
}

const getNextInstance = (instances: EventInstance[] = []) => {
  const now = new Date();
  return instances
    .filter((instance) => new Date(instance.startDate) > now)
    .sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    )[0];
};

const useEventInstances = () => {
  const { data: instances, isLoading } = useGetList("eventInstances", {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: "startDate", order: "DESC" },
  });
  const instancesByEventId = useMemo(() => {
    if (!instances) return new Map();

    const lookup = new Map();
    instances.forEach((instance) => {
      if (!lookup.has(instance.eventId)) {
        lookup.set(instance.eventId, []);
      }
      lookup.get(instance.eventId).push(instance);
    });
    return lookup;
  }, [instances]);

  const getNextInstanceForEvent = useCallback(
    (eventId: string) => {
      const eventInstances = instancesByEventId.get(eventId);
      console.log({ eventInstances });
      return getNextInstance(eventInstances);
    },
    [instancesByEventId]
  );

  return {
    instancesByEventId,
    getNextInstanceForEvent,
    isLoading,
  };
};

const NextEventInstanceField = ({
  getNextInstance,
}: {
  getNextInstance: (eventId: string) => any;
}) => {
  const record = useRecordContext();
  const nextInstance = getNextInstance(record?.id.toString());

  if (!nextInstance) {
    return <span>No upcoming instances</span>;
  }

  return (
    <DateField
      record={nextInstance}
      source="startDate"
      label="Next Date"
      sortBy="startDate"
    />
  );
};

const NextEventInstanceSalesField = ({
  getNextInstance,
}: {
  getNextInstance: (eventId: string) => any;
}) => {
  const record = useRecordContext();
  const nextInstance = getNextInstance(record?.id.toString());
  console.log({ ev: record.name, nextInstance });
  if (!nextInstance) {
    return <span>No upcoming instances</span>;
  }

  return (
    <ReferenceManyCount
      reference="passes"
      target="eventId"
      label="Sold"
      filter={{ instanceId: nextInstance.id }}
    />
  );
};

const EventList = (props: any) => {
  const { getNextInstanceForEvent } = useEventInstances();

  return (
    <List {...props} filters={<EventFilter />}>
      <Datagrid rowClick="show">
        <ImageField source="mainImage.src" label="Image" />
        <WrapperField label="Event Name" sortBy="name">
          <TextField source="name" /> <br />
          <EventLinkField />
        </WrapperField>
        <WrapperField label="Next Date">
          <NextEventInstanceField getNextInstance={getNextInstanceForEvent} />
        </WrapperField>
        <WrapperField label="Sold">
          <NextEventInstanceSalesField
            getNextInstance={getNextInstanceForEvent}
          />
        </WrapperField>
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

const EventLinkField = () => {
  const record = useRecordContext();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://colalounge.com/events/${record.id}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box display="flex" alignItems="center">
      <a href={`https://colalounge.com/events/${record.id}`}>
        colalounge.com/events/{record.id}
      </a>
      <IconButton onClick={handleCopy} size="small" sx={{ ml: 1 }}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
      {copied && (
        <Typography variant="caption" sx={{ ml: 1 }}>
          Copied!
        </Typography>
      )}
    </Box>
  );
};

export const EventShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Summary">
        <TextField source="id" />
        <EventLinkField />
        <TextField source="name" />
        <ImageField source="mainImage.src" label="Main Image" />

        <ArrayField source="additionalImages" label="Additional Images">
          <Datagrid>
            <ImageField source="src" />
            <TextField source="title" />
          </Datagrid>
        </ArrayField>
        <TextField source="summary" />
        <RichTextField source="description" label="Event Description" />
        <TextField source="ticketlink" label="Ticket Link" />
        <ArrayField source="tickets" label="Tickets">
          <Datagrid>
            <TextField source="id" label="Ticket ID" />
            <TextField source="name" label="Ticket Name" />
            <TextField source="description" label="Ticket Description" />
            <TextField source="amount" label="Price in cents" />
          </Datagrid>
        </ArrayField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Instances">
        <ReferenceManyField reference="eventInstances" target="eventId">
          <Datagrid>
            <DateField source="startDate" label="Starts At" showTime />
            <DateField source="endDate" label="Ends At" showTime />
            <TextField source="description" label="Description" />
          </Datagrid>
          <Pagination />
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Sales">
        <ReferenceManyField
          label="Sold"
          reference="passes"
          target="eventId"
          filter={{ status: "pending" }}
        >
          <Datagrid>
            <DateField source="createdate" />
            <TextField source="name" />
            <ReferenceField source="memberId" reference="members">
              <TextField source="displayName" />
              <TextField source="email" />
            </ReferenceField>
            <TextField source="amount" />
            <TextField source="status" />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export const EventCreate = (props: any) => (
  <Create {...props} transform={transformEventData}>
    <SimpleForm>
      <TextInput
        source="id"
        label="Event Slug"
        required
        helperText="unique-name-of-event"
      />
      <TextInput source="name" label="Event Name" fullWidth />
      <BooleanInput source="isActive" />

      <ImageInput source="mainImage" label="Main Image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>

      <ArrayInput source="additionalImages" label="Additional Images">
        <SimpleFormIterator>
          <ImageInput source="src" label="Image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="title" label="Image Title" />
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput source="summary" fullWidth />
      <RichTextInput source="description" label="Event Description" fullWidth />
      <Divider sx={{ width: "100%" }} />
      <TextInput
        source="location.name"
        label="Location Name"
        defaultValue={"The Cola Lounge"}
      />
      <TextInput
        source="location.address"
        label="Location Address"
        defaultValue={"2834 Cherokee Street, 63118"}
      />
      <Box
        sx={{
          p: 2,
          mt: 2,
          mb: 2,
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tickets
        </Typography>
        <ArrayInput source="tickets" label="">
          <SimpleFormIterator>
            <TextInput source="id" label="Ticket ID" />
            <TextInput source="name" label="Ticket Name" />
            <TextInput source="description" label="Ticket Description" />
            <NumberInput source="amount" label="Price in cents" />
            <TextInput source="productType" label="" defaultValue={"ticket"} />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <DateInput source="createdate" defaultValue={new Date()} disabled />
      <DateInput source="lastupdate" defaultValue={new Date()} disabled />
    </SimpleForm>
  </Create>
);

export const EventEdit = (props: any) => {
  return (
    <Edit {...props} transform={transformEventData}>
      <SimpleForm sanitizeEmptyValues>
        <TextInput disabled source="id" label="Event Slug" />
        <BooleanInput source="isActive" />
        <TextInput source="name" label="Event Name" fullWidth />
        {/* Main Image */}
        <ImageInput source="mainImage" label="Main Image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <ArrayInput source="additionalImages" label="Additional Images">
          <SimpleFormIterator>
            <ImageInput source="src" label="Image" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="title" label="Image Title" />
          </SimpleFormIterator>
        </ArrayInput>

        <TextInput source="summary" fullWidth />
        <RichTextInput
          source="description"
          label="Event Description"
          defaultValue=""
          fullWidth
        />
        <TextInput source="location.name" label="Location Name" fullWidth />
        <TextInput
          source="location.address"
          label="Location Address"
          fullWidth
        />
        <Box
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Instances
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <ReferenceManyField reference="eventInstances" target="eventId">
            <Box>
              <Datagrid>
                <DateField source="startDate" label="Starts At" showTime />
                <DateField source="endDate" label="Ends At" showTime />
                <TextField source="description" label="Description" />
                <EditButton />
              </Datagrid>
              <CreateEventInstanceButton />
            </Box>
          </ReferenceManyField>
        </Box>
        <Box
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Tickets
          </Typography>
          <TextInput source="ticketlink" label="Ticket Link" fullWidth />

          <ArrayInput source="tickets" label="">
            <SimpleFormIterator inline>
              <TextInput source="id" label="Ticket ID" />
              <TextInput source="name" label="Ticket Name" />
              <TextInput source="description" label="Ticket Description" />
              <NumberInput source="amount" label="Price" />
              <TextInput source="productType" defaultValue="ticket" disabled />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput disabled source="createdate" />
          </Grid>
          <Grid item xs={6}>
            <DateInput disabled source="lastupdate" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const EventsAdmin = {
  list: EventList,
  show: EventShow,
  create: EventCreate,
  edit: EventEdit,
};
export default EventsAdmin;
